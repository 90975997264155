@font-face {
  font-family: 'Maax';
  src: url('/public/fonts/maax-medium-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App{
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.Leftmenu 
{
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start; 
}
.Leftmenu div {
  text-align: center;
}
.LeftLogo{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.Content{
  background-color: white;
  display: flex;
  border-radius: 1rem;
  margin: 1rem 1rem 1rem 0;
  width: calc(100vw - 2rem); /* Subtract margins from width */
  height: calc(100vh - 2rem);
  box-sizing: border-box;  
}
.ScrollableContent{
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
}
.ModalContent{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  box-sizing: border-box;  
}
h1, h2 {
  font-family: 'Maax', sans-serif;
}
.InvertedText{
  color: white;
}